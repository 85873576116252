import styled from 'styled-components';
import { space, layout } from 'styled-system';

export const Container = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 0.5rem;
  ${space};
  ${layout};

  @media (min-width: 1280px) {
    padding: 0;
  }
`;
